import { type StateAbbrValue } from '@innerwell/us-states';

export type SoharVerificationResultResponseDto = {
   benefits: SoharBenefit[];
   memberId: string; // The insurance member ID
   patient: SoharPatient;
   patientId: string; // The Sohar Health ID for the patient
   payerId: string; // The payer ID. Note that this value may be different to the value provided in the original request if the original payer was incorrect and the correct payer has been found
   payerName: string; // The payer's name. Note that this value may be different to the value provided in the original request if the original payer was incorrect and the correct payer has been found
   relatedEntities: SoharRelatedEntity[];
   status: SoharEligibilityStatus;
   subscriber: {
      firstName: string | null;
      lastName: string | null;
   };
   timestamp: string; // An ISO 8601 date and time string describing when the verification was created
   verificationId: string; // The Sohar Health ID for the verification
   coverageEndDate: string | null; // Date on which coverage ends in MM/DD/YYYY format
   coverageStartDate: string | null; // Date on which coverage began in MM/DD/YYYY format
   groupName: string | null; //Describes the patient's group name
   groupNumber: string | null;
   id: string | null; // An optional identifier for the verification. This is a user-defined value that can be used when creating the verification
   insuranceTypeCode: InsuranceTypeCodes | null; // Describes the patient's insurance type
   planName: string | null; // Describes the patient's plan name
};

export type SoharBenefit = {
   coinsurance: number | null; // The co-insurance value. Has the value 1 if co-insurance is 100%, indicating that the payer will not reimburse for this network status
   copay: number | null; // The co-payment value, in USD
   coverageLevelCode: string | null; // Indicates the coverage level of the provided deductible information. Possible values include: IND, FAM, CHD, DEP, ECH, EMP, ESP, SPC, SPO
   networkStatus: SoharBenefitsNetworkStatus; // Determines whether the benefits apply to patients in or out of network. Values are either "inn" for in network benefits or "oon" for out of network benefits
   outOfPocket: number | null; // The patient's out of pocket maximum value, in USD. Also known as the stop loss value
   outOfPocketRemaining: number | null; // The patient's remaining out of pocket value, in USD. Also known as the stop loss value
   remainingDeductible: number | null; // The patient's remaining deductible value, in USD
   tier: string | null; // The benefits tier, if multiple benefits are available. This attribute will be null if only one tier is available.
   totalDeductible: number | null; // The patient's total deductible value, in USD
};

export enum SoharBenefitsNetworkStatus {
   Inn = 'inn',
   Oon = 'oon',
}

export type SoharPatient = {
   dateOfBirth: string; // The patient's birth date in MM/DD/YYYY format
   firstName: string;
   lastName: string;
   state: StateAbbrValue; // Two-letter US state code
   address: string | null;
   subscriberRelationshipCode: SoharSubscriberRelationshipCode | null; // The relationship between the patient and the subscriber. Possible values can be found here.
};

export enum SoharSubscriberRelationshipCode {
   Spouse = '01', // Spouse
   GrandfatherOrGrandmother = '04', // Grandfather or Grandmother
   GrandsonOrGranddaughter = '05', // Grandson or Granddaughter
   NephewOrNiece = '07', // Nephew or Niece
   FosterChild = '10', // Foster Child
   WardOfTheCourt = '15', // Ward of the Court
   StepsonOrStepdaughter = '17', // Stepson or Stepdaughter
   Self = '18', // Self
   Child = '19', // Child
   Employee = '20', // Employee
   Unknown = '21', // Unknown
   HandicappedDependent = '22', // Handicapped/Dependent
   SponsoredDependent = '23', // Sponsored Dependent
   DependentOfMinorDependent = '24', // Dependent of Minor Dependent
   Guardian = '26', // Guardian
   SignificantOther = '29', // Significant Other
   BothParents = '30', // Both Parents - The legal custody of the student is with both parents
   CourtAppointedGuardian = '31', // Court Appointed Guardian
   Mother = '32', // Mother
   Father = '33', // Father
   EmancipatedMinor = '36', // Emancipated Minor
   OrganDonor = '39', // Organ Donor
   CadaverDonor = '40', // Cadaver Donor
   InjuredPlaintiff = '41', // Injured Plaintiff
   ChildNoFinancialResponsibility = '43', // Child Where Insured Has No Financial Responsibility
   LifePartner = '53', // Life Partner
   OtherRelationship = 'G8', // Other Relationship
}

export type SoharRelatedEntity = {
   entityIdentifierCode: string | null; // Specifies the role of a related entity. Used to identify primary, secondary, or tertiary payers with the values PRP (Primary Payer), SEP (Secondary Payer), or TTP (Tertiary Payer)
   memberId: string | null; // The insurance member ID, if the related entity knows the patient by a different member ID
   payerId: string | null; // The payer ID of the related entity
   payerName: string; // The name of the related entity
};

export type SoharVerificationIdentifier = {
   verificationId: string;
   patientId: string;
   id: string | null;
   status: number;
};

export type SoharVerificationResponseDto = {
   results: SoharVerificationIdentifier[];
};

export enum SoharEligibilityStatus {
   CompleteEligible = 'complete.eligible', // The verification was successfully completed; Patient is confirmed active coverage and covered for the provided taxonomy code.
   CompleteIneligible = 'complete.ineligible', // The verification was successfully completed; Patient is either inactive and/or not covered for the provided taxonomy code.
   ErrorMemberId = 'error.member.id', // The member ID was not recognized by the payer, or a different payer ID should be used.
   ErrorMemberDob = 'error.member.dob', // The patient’s date of birth was not recognized by the payer, or a different payer ID should be used.
   ErrorMemberName = 'error.member.name', // The patient’s name was not recognized by the payer, or a different payer ID should be used. This is often due to incorrect spelling or nicknames.
   ErrorPayer = 'error.payer', // The payer has been unavailable for over 24 hours and the Sohar Health Operations team have been unable to resolve the verification via payer portal or phone call.
   ErrorPayerNpi = 'error.payer.npi', // The provided NPI is not recognized by the payer. Some payers require an NPI on file in order to provide benefits. Some payers require a rendering NPI in order to provide benefits, for example Blue Cross Blue Shield of Texas.
   Pending = 'pending', // The verification was resolved immediately, typically because the payer API was unavailable.
}

export enum InsuranceTypeCodes {
   ShortTermInsurance = '01', // Short Term Insurance
   TRICARE = '02', // TRICARE
   MedicareMedicaidDualEligible = '03', // Medicare and Medicaid Dual Eligible
   DisabilityInsurance = 'D', // Disability Insurance
   MedicareAdvantagePOSExcludesPartD = 'M', // Medicare Advantage Point of Service (POS) Plan that excludes Part D coverage
   MedicareSecondaryWorkingAged = '12', // Medicare is secondary, Working Aged Beneficiary or Spouse with employer group health plan is primary
   MedicareSecondaryEndStageRenal = '13', // Medicare is secondary, End-Stage Renal Disease Beneficiary in the mandated coordination period with an employer's group health plan is primary
   MedicareSecondaryNoFaultInsurance = '14', // Medicare is secondary, no-fault insurance, including auto, is primary
   MedicareSecondaryWorkersCompensation = '15', // Medicare is secondary, Worker's Compensation is primary
   MedicareSecondaryPublicHealthService = '16', // Medicare is secondary, Public Health Service (PHS) or Other Federal Agency is primary
   DentalInsurance = '17', // Dental Insurance
   VisionInsurance = '18', // Vision Insurance
   PrescriptionDrugInsurance = '19', // Prescription Drug Insurance
   MedicareSecondaryBlackLung = '41', // Medicare is secondary, Black Lung is primary
   MedicareSecondaryVeteransAdministration = '42', // Medicare is secondary, Veteran's Administration is primary
   MedicareSecondaryDisabledBeneficiary = '43', // Medicare is secondary, Disabled Beneficiary Under Age 65 with a large group health plan (LGHP) is primary
   MedicareSecondaryOtherLiabilityInsurance = '47', // Medicare is secondary, other liability insurance is primary
   Medicaid = '48', // Medicaid
   MedicareMedicaidDualEligible2 = '49', // Medicare and Medicaid Dual Eligible
   AutomobileInsurance = 'AP', // Automobile Insurance
   CommercialInsurance = 'C1', // Commercial Insurance
   COBRA = 'CO', // Beneficiary is under Consolidated Omnibus Budget Reconciliation Act (COBRA) coverage
   ExclusiveProviderOrganization = 'EP', // Exclusive Provider Organization (EPO) Plan
   HealthInsuranceExchangeBronze = 'HB', // Health Insurance Exchange (HIX) Bronze
   HighDeductibleHealthPlan = 'HD', // High Deductible Health Plan (HDHP)
   HealthInsuranceExchangeGold = 'HG', // Health Insurance Exchange (HIX) Gold
   HealthMaintenanceOrganization = 'HM', // Health Maintenance Organization (HMO) Plan
   HealthInsuranceExchangePlatinum = 'HP', // Health Insurance Exchange (HIX) Platinum
   HealthInsuranceExchangeSilver = 'HS', // Health Insurance Exchange (HIX) Silver
   IndemnityPlan = 'IN', // Indemnity Plan
   LongTermCareInsurance = 'LC', // Long Term Care Insurance
   LifeInsurance = 'LI', // Life Insurance
   ImpactedByLitigation = 'LT', // Impacted by litigation
   MedicarePartA = 'MA', // Medicare Part A
   MedicarePartB = 'MB', // Medicare Part B
   MedicarePartD = 'MD', // Medicare Part D
   MedicareAdvantagePPOExcludesPartD = 'ME', // Medicare Advantage Preferred Provider Organization (PPO) Plan that excludes Part D Coverage
   MedicareAdvantageHMOIncludesPartD = 'MJ', // Medicare Advantage Health Maintenance Organization (HMO) Plan that includes Part D Coverage
   MedicareAdvantageHMORiskIncludesPartD = 'MK', // Medicare Advantage Health Maintenance Organization (HMO) Risk Plan that includes Part D Coverage
   MedicareAdvantageIndemnityIncludesPartD = 'ML', // Medicare Advantage Indemnity Plan that includes Part D Coverage
   MedicareAdvantagePPOIncludesPartD = 'MM', // Medicare Advantage Preferred Provider Organization (PPO) Plan that includes Part D Coverage
   MedicareAdvantageIndemnityExcludesPartD = 'MN', // Medicare Advantage Indemnity Plan that excludes Part D coverage
   MedicareAdvantagePOSIncludesPartD = 'MO', // Medicare Advantage Point of Service (POS) Plan that includes Part D Coverage
   MedicareAdvantageHMORiskExcludesPartD = 'MR', // Medicare Advantage Health Maintenance Organization (HMO) Risk Plan that excludes Part D coverage
   MedicareAdvantageHMOExcludesPartD = 'MT', // Medicare Advantage Health Maintenance Organization (HMO) Plan that excludes Part D coverage
   OpenAccessPOS = 'OA', // Open Access Point of Service Plan (POS) Plan
   PropertyInsurancePersonal = 'PE', // Property Insurance - Personal
   PreferredProviderOrganization = 'PR', // Preferred Provider Organization (PPO) Plan
   PointOfServicePlan = 'PS', // Point of Service (POS) Plan
   PropertyInsuranceReal = 'RP', // Property Insurance - Real
   SetAsideArrangement = 'SA', // Set Aside Arrangement
   SupplementalInsurance = 'SP', // Supplemental Insurance
   WorkersCompensationInsurance = 'WC', // Workers Compensation Insurance
   WrapUpInsurance = 'WU', // Wrap-Up Insurance
}

export type SoharWebhookResponseDto = {
   carveOut: {
      payerId: string; // UUID4 used to identify the payer with Sohar Health
      payerName: string; // The name of the payer
   } | null;
   coverageEndDate: string; // The date coverage will end. A NULL value, or a value of 12/31/9999 indicates indefinite coverage with no defined end date
   coverageStartDate: string; // The date coverage began
   groupNumber: string;
   id: string;
   inn: {
      coinsurance: number | null;
      copay: number | null;
      cost: number;
      outOfPocket: number | null;
      outOfPocketRemaining: number | null;
      priorAuthRequired: boolean;
      remainingDeductible: number | null;
      totalDeductible: number | null;
   };
   insuranceTypeCode: InsuranceTypeCodes; // Describes the patient's insurance type
   memberId: string; // The patient's member ID used to identify them with the payer
   oon: {
      coinsurance: number | null;
      copay: number | null;
      cost: number;
      outOfPocket: number | null;
      outOfPocketRemaining: number | null;
      priorAuthRequired: boolean;
      remainingDeductible: number | null;
      totalDeductible: number | null;
   };
   patientId: string; // UUID4 used to identify the patient with Sohar Health
   patientUpdated: boolean;
   payerId: string; // UUID4 used to identify the payer with Sohar Health
   payerName: string; // The name of the payer
   planName: string; // The name of the plan
   sandbox: boolean; // Indicates whether this is a sandbox or production verification
   status: SoharEligibilityStatus;
   timestamp: string; // Indicates when the verification was created
   verificationId: string; // UUID4 used to identify the verification with Sohar Health
};
